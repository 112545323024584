import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { memo } from "react";
import { fontWeight500 } from "~/css/font";
import { fill_horizontal_all_center } from "~/modules/AppLayout/FlexGridCss";
import { useFirebaseMeState } from "~/modules/auth/containers/useFirebaseMeState";
export const GoogleLoginComponent = memo(function GoogleLoginComponent() {
    const auth = useFirebaseMeState.useContainer();
    const router = useRouter();
    return (<googleButtonStyled.button onClick={() => {
            auth.acts.loginWithGoogle().then(() => {
                router.reload();
            });
        }}>
      <googleButtonStyled.logo src='google-icon.png'/>
      會員登入 / 開始試用
    </googleButtonStyled.button>);
});
const googleButtonStyled = {
    button: styled.div `
    ${fill_horizontal_all_center};
    ${fontWeight500};
    padding: 0px 16px;
    width: 240px;
    height: 40px;
    background-color: #ffffff;
    border-radius: 8px;
    border: 1px solid #aaaaaa;
    color: #7b7b7b;
    gap: 4px;
    cursor: pointer;
    transition: 0.3s;
    &:hover {
      background-color: #f1f1f1;
      width: 248px;
      height: 40px;
      font-size: 16.8px;
      transition: 0.3s;
    }
  `,
    logo: styled.img `
    width: 32px;
  `,
};
