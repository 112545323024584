import styled from '@emotion/styled';
import { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { fill_horizontal_all_center, fill_vertical_all_center, jc, } from '~/modules/AppLayout/FlexGridCss';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { GoogleLoginComponent } from './GoogleLoginComponent';
import { keyframes } from '@emotion/react';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { Avatar, Button, CopyButton } from '@mantine/core';
import { useFirebaseMeState } from '~/modules/auth/containers/useFirebaseMeState';
import { ProductKey } from '~/pages/win988/component/ProductKey';
import { useThemeStore } from '~/components/theme/useThemeStore';
/** 提示瀏覽器封鎖相關問題 */
const GuidanceTtips = memo(function GuidanceTtips() {
    const url = window.location.href;
    const theme = useThemeStore(t => t.theme);
    return (<styleds.tips.container className={theme}>
      <styleds.tips.text>
        如被封鎖存取權，請點擊複製網址按鈕至其他瀏覽器貼上繼續使用頁面。
      </styleds.tips.text>
      <CopyButton value={url}>
        {({ copied, copy }) => (<Button color={copied ? 'teal' : 'blue'} onClick={copy} radius='xs' size='xs' compact>
            {copied ? '已複製網址' : '複製網址'}
          </Button>)}
      </CopyButton>
    </styleds.tips.container>);
});
/** 尚未使用Google帳號登入 */
const UnLoginView = memo(function UnLoginView(props) {
    const theme = useThemeStore(t => t.theme);
    return (<styleds.main.card className={theme}>
      <styleds.login.faviconContent>
        <styleds.login.logo src={fr_agents.config.favicon}/>
        <styleds.login.title className={theme}>{fr_agents.config.title}</styleds.login.title>
      </styleds.login.faviconContent>
      <GoogleLoginComponent />
      <GuidanceTtips />
      <styleds.main.copyright className={theme}>{props.copyright}</styleds.main.copyright>
    </styleds.main.card>);
});
/** 尚未開通會員或是會員期限已到期 */
const UnregisteredView = memo(function UnregisteredView(props) {
    const theme = useThemeStore(t => t.theme);
    const firebaseMe = useFirebaseMeState.useContainer();
    const meUserState = useMeStore(state => state.meUserState);
    const activationCode = props.activationCode;
    /** 會員名稱 */
    const userName = meUserState?.name ?? '-';
    /** 會員代碼 */
    const userNumber = useMeStore(state => state.meUserState?.code);
    /** 會員信箱 */
    const email = useMeStore(state => state.meUserState?.email);
    /** 會員大頭貼 */
    const photoUrl = useMeStore(state => state.meUserState?.photo);
    /** 會員大頭貼 */
    const userPicture = (<Avatar src={photoUrl} size={72} radius='lg' alt="it's me"/>);
    return (<styleds.main.card className={theme}>
        <styleds.register.useInfo>
          {userPicture}
          <styleds.register.item className={theme}>
            <styleds.register.title className={theme}>會員名稱</styleds.register.title>
            <span>{userName}</span>
          </styleds.register.item>

          <styleds.register.item className={theme}>
            <styleds.register.title className={theme}>會員代碼</styleds.register.title>
            <span>{userNumber}</span>
          </styleds.register.item>

          <styleds.register.item className={theme}>
            <styleds.register.title className={theme}>會員信箱</styleds.register.title>
            <span>{email}</span>
          </styleds.register.item>
        </styleds.register.useInfo>

        <styleds.register.warring>
          <span>權限尚未開通</span>
          <span>聯繫客服專員</span>
        </styleds.register.warring>

        {activationCode === true && (<styleds.register.productKey className={theme}>
            <ProductKey />
          </styleds.register.productKey>)}
        <styleds.register.logout className={theme} onClick={() => firebaseMe.acts.logout()}>
          登出
        </styleds.register.logout>
        <styleds.main.copyright className={theme}>{props.copyright}</styleds.main.copyright>
      </styleds.main.card>);
});
//選項: 1.試用碼 2.金管會字號
export const InvestmentLoginView = memo(function InvestmentLoginView(props) {
    const meFirebaseState = useMeStore(state => state.meFirebaseState);
    const activationCode = props.activationCode ?? false;
    const { isPhone, isPc } = useMedia();
    const theme = useThemeStore(t => t.theme);
    return (<styleds.main.container className={theme}>
      {!meFirebaseState && <UnLoginView copyright={props.copyright}/>}
      {meFirebaseState && (<UnregisteredView activationCode={activationCode} copyright={props.copyright}/>)}
    </styleds.main.container>);
});
const fadeIn = keyframes `
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const styleds = {
    main: {
        container: styled.div `
      position: fixed;
      z-index: 1000;
      ${fill_vertical_all_center};
      background-size: cover;
      padding: 8px;
      &.dark {
        background-image: linear-gradient(90deg, #181a1ddd, #181a1ddd),
          url(https://images.unsplash.com/photo-1640340434855-6084b1f4901c?auto=format&fit=crop&q=80&w=2000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
      }
      &.light {
        background-image: linear-gradient(90deg, #fafafa7a, #fafafa7a),
          url(https://images.unsplash.com/photo-1560221328-12fe60f83ab8?auto=format&fit=crop&q=80&w=2974&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D);
      }
    `,
        card: styled.div `
      ${fill_vertical_all_center};
      width: 316px;
      height: 560px;
      border-radius: 16px;
      padding: 16px;
      animation: ${fadeIn} 2s;
      &.dark {
        background: linear-gradient(85deg, #36383baa, #36383baa);
        border: 1.6px solid #606165;
      }
      &.light {
        background: linear-gradient(85deg, #ffffffdd, #ffffffdd);
        border: 1.6px solid #acacac;
      }
    `,
        copyright: styled.div `
      font-size: 10px;
      margin-top: auto;
      &.dark {
        color: #fafafa;
      }
      &.light {
        color: #252525;
      }
    `,
    },
    login: {
        faviconContent: styled.div `
      ${fill_vertical_all_center};
      width: auto;
      height: auto;
      min-height: 198px;
      border-radius: 6px;
      padding: 32px;
      margin-bottom: 24%;
    `,
        logo: styled.img `
      width: 132px;
    `,
        title: styled.div `
      ${fill_horizontal_all_center};
      height: 48px;
      font-size: 24px;
      font-weight: bold;
      &.dark {
        color: #fafafa;
      }
      &.light {
        color: #353535;
      }
    `,
    },
    register: {
        useInfo: styled.div `
      ${fill_vertical_all_center};
      height: auto;
      margin-top: auto;
      gap: 6px;
    `,
        item: styled.div `
      ${fill_vertical_all_center};
      width: 240px;
      height: 52px;
      padding: 4px;
      gap: 2px;
      font-size: 14px;
      &.dark {
        color: #fafafa;
      }
      &.light {
        color: #252525;
      }
    `,
        title: styled.div `
      ${fill_horizontal_all_center};
      width: 96px;
      height: 28px;
      border-radius: 16px;
      &.dark {
        background-color: #3b4454;
      }
      &.light {
        background-color: #dedee6;
      }
    `,
        warring: styled.div `
      ${fill_vertical_all_center};
      width: 240px;
      height: 56px;
      background-color: #ff0000cc;
      border-radius: 4px;
      font-size: 14px;
      gap: 8px;
      margin-top: auto;
    `,
        productKey: styled.div `
      margin-top: auto;
      font-size: 14px;
      padding: 0 8px;
      border-radius: 6px;
      &.dark {
        background-color: #3a3a3d88;
        color: #fafafa;
      }
      &.light {
        background-color: #e1e4ea;
        color: #4c4c4c;
      }
    `,
        logout: styled.div `
      margin-top: auto;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      &.dark {
        color: #fafafa;
      }
      &.light {
        color: #252525;
      }
    `,
    },
    tips: {
        container: styled.div `
      ${fill_vertical_all_center};
      ${jc.spaceBetween};
      width: 240px;
      height: 88px;
      border-radius: 5px;
      padding: 8px;
      margin-top: 24%;
      &.dark {
        background-color: #424242;
        color: #fafafa;
      }
      &.light {
        background-color: #ffffff;
        color: #353535;
      }
    `,
        text: styled.div `
      ${fill_vertical_all_center};
      height: 40px;
      width: 240px;
      font-size: 13px;
      padding: 8px;
    `,
    },
};
//balck
//https://images.unsplash.com/photo-1640340434855-6084b1f4901c?auto=format&fit=crop&q=80&w=2000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
//light
//https://images.unsplash.com/photo-1560221328-12fe60f83ab8?auto=format&fit=crop&q=80&w=2974&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D
